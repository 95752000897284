import React from 'react';
import { useCookiebot } from '../CookiebotProvider/CookiebotProvider';

interface ABTestingContextType {
    isPageVariationEnabled: () => boolean;
}

const ABTestingContext = React.createContext<ABTestingContextType>({
    isPageVariationEnabled: () => false,
});

/**
 * To add a new hardcoded experiment on Convert, just make the experiment add it like this:
 * window.usePageVariation = { [url]: true };
 *
 * Replacing [url] with the url you want the experiment to fire on. Eg:
 * window.usePageVariation = { '/booking/date': true };
 *
 * @param children
 * @constructor
 */
export const ABTestingProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const { statistics } = useCookiebot();

    React.useEffect(() => {
        // start convert on private mode, loading experiments but not recording data
        // eslint-disable-next-line max-len
        // https://support.convert.com/hc/en-us/articles/360037946851-delay-cookie-writing-and-data-collection-until-visitor-consent-is-provided
        if (typeof window !== 'undefined') {
            window._conv_q = window._conv_q || [];

            if (!statistics) {
                window._conv_q.push(['consentRequired']);
            } else {
                window._conv_q.push(['consentGiven']);
            }
        }
    }, [statistics]);

    const isPageVariationEnabled = (): boolean => {
        if (typeof window !== 'undefined' && window.location) {
            const currentUrl = window.location.pathname;
            const experimentConfig = window.usePageVariation;

            return Boolean(experimentConfig?.[currentUrl]);
        }
        return false;
    };

    return (
        <ABTestingContext.Provider value={{ isPageVariationEnabled }}>
            {children}
        </ABTestingContext.Provider>
    );
};

export const useABTesting = (): ABTestingContextType => {
    const context = React.useContext(ABTestingContext);
    if (!context) {
        console.error('useABTesting must be used within an ABTestingProvider');
    }
    return context;
};
