import * as React from 'react';
import { ScriptStrategy } from 'gatsby';
import { CookieType } from 'lib/types';
import renderScript from '../lib/renderScript';

type ScriptProps = {
    src?: string;
    content?: string;
    timeoutMs?: number;
    strategy: Exclude<ScriptStrategy, ScriptStrategy.offMainThread>;
    attributes?: Record<string, string | undefined>;
    id?: string;
    onLoad?: () => void;
    type?: string;
    cookieType: CookieType;
};

export default function Script({
    strategy,
    src,
    content,
    timeoutMs = 0,
    attributes,
    id,
    onLoad,
    type,
    cookieType,
}: ScriptProps) {
    React.useEffect(() => {
        return renderScript(
            strategy,
            cookieType,
            timeoutMs,
            src,
            content,
            attributes,
            id,
            onLoad,
            type,
        );
    }, [
        src,
        content,
        timeoutMs,
        strategy,
        attributes,
        id,
        cookieType,
        onLoad,
        type,
    ]);

    return null;
}
