import * as React from 'react';

const ENABLE_COOKIEBOT = process.env.GATSBY_ENABLE_COOKIEBOT === 'true';
const COOKIEBOT_ID = process.env.GATSBY_COOKIEBOT_ID;

export const IS_COOKIEBOT_ENABLED = ENABLE_COOKIEBOT && COOKIEBOT_ID;

export default function CookiebotScript() {
    // Consent Mode Documentation:
    // https://developers.google.com/tag-platform/security/guides/consent
    if (IS_COOKIEBOT_ENABLED) {
        return (
            <>
                <script
                    id="Cookiebot"
                    type="text/javascript"
                    src="https://consent.cookiebot.com/uc.js"
                    data-cbid={COOKIEBOT_ID}
                    // data-debug-mode="true"
                />
                {/* Google Consent Mode Configuration */}
                <script
                    data-cookieconsent="ignore"
                    dangerouslySetInnerHTML={{
                        __html: `
                            window.dataLayer = window.dataLayer || [];
                            function gtag() {
                                dataLayer.push(arguments);
                            }
                            gtag("consent", "default", {
                                ad_personalization: "denied",
                                ad_storage: "denied",
                                ad_user_data: "denied",
                                analytics_storage: "denied",
                                functionality_storage: "denied",
                                personalization_storage: "denied",
                                security_storage: "granted",
                                wait_for_update: 500,
                            });
                            gtag("set", "ads_data_redaction", true);
                            gtag("set", "url_passthrough", true);
                        `,
                    }}
                />
            </>
        );
    }

    return null;
}
