import * as React from 'react';
import CookiebotContext, { CookieConsentState } from './context';

export default function CookiebotProvider({
    children,
}: React.PropsWithChildren<unknown>): React.ReactElement {
    const [cookieConsent, setCookieConsent] =
        React.useState<CookieConsentState>({
            necessary: false,
            preferences: false,
            statistics: false,
            marketing: false,
        });

    // Use a ref to store the current consent state
    const cookieConsentRef = React.useRef<CookieConsentState>(cookieConsent);

    React.useEffect(() => {
        cookieConsentRef.current = cookieConsent;
    }, [cookieConsent]);

    // See "runScripts" on the documentation: https://www.cookiebot.com/en/developer/
    // When content gets added after the consent had been loaded, it only gets updated
    // with a call to Cookiebot.runScripts.
    // Scripts are only executed once, so this function is safe to call several times.
    const runCookiebotScripts = React.useCallback(() => {
        if (
            typeof window.Cookiebot !== 'undefined' &&
            typeof window.Cookiebot.runScripts === 'function'
        ) {
            window.Cookiebot.runScripts();
        }
    }, []);

    // Partytown requires the scripts to have "text/partytown" as type
    // Cookiebot requires them to have "text/plain" as type
    // After Cookiebot updates them, we need to set them to the Partytown type to fetch them
    const updatePartytownScripts = React.useCallback(() => {
        // Only process scripts marked for Partytown
        const scripts = document.querySelectorAll(
            '[data-partytown="true"]:not([data-processed="true"])',
        );

        scripts.forEach((script) => {
            const cookieType = script.getAttribute(
                'data-cookieconsent',
            ) as keyof CookieConsentState;
            const isAllowed =
                cookieType && cookieConsentRef.current[cookieType];

            if (isAllowed) {
                // Update script to "text/partytown" and mark it as processed
                script.setAttribute('type', 'text/partytown');
                script.setAttribute('data-processed', 'true'); // Mark as processed

                window.dispatchEvent(new CustomEvent('ptupdate'));
            }
        });
    }, [cookieConsentRef]);

    React.useEffect(() => {
        // Helper function to get the latest consent values
        const getConsentState = (): CookieConsentState => {
            if (typeof window.Cookiebot !== 'undefined') {
                const consent = window.Cookiebot.consent || {};
                return {
                    necessary: consent.necessary || false,
                    preferences: consent.preferences || false,
                    statistics: consent.statistics || false,
                    marketing: consent.marketing || false,
                };
            }
            return {
                necessary: false,
                preferences: false,
                statistics: false,
                marketing: false,
            };
        };

        // Set initial state when the Provider is mounted
        const handleConsentReady = () => {
            setCookieConsent(getConsentState());
        };

        // Update state on consent change
        const handleConsentChange = () => {
            setCookieConsent(getConsentState());
            updatePartytownScripts();
        };

        // Listen for Cookiebot events
        if (typeof window !== 'undefined') {
            window.addEventListener(
                'CookiebotOnConsentReady',
                handleConsentReady,
            );
            window.addEventListener(
                'CookiebotOnConsentChange',
                handleConsentChange,
            );

            // If Cookiebot is already initialized, immediately retrieve the consent state
            if (window.Cookiebot && window.Cookiebot.consent) {
                handleConsentReady();
            }
        }

        // Cleanup listeners on unmount
        return () => {
            window.removeEventListener(
                'CookiebotOnConsentReady',
                handleConsentReady,
            );
            window.removeEventListener(
                'CookiebotOnConsentChange',
                handleConsentChange,
            );
        };
    }, [updatePartytownScripts]);

    React.useEffect(() => {
        const observer = new MutationObserver(() => {
            runCookiebotScripts();
            updatePartytownScripts();
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });

        // Run scripts initially in case of any already loaded dynamic content
        runCookiebotScripts();
        updatePartytownScripts();

        return () => {
            observer.disconnect();
        };
    }, [runCookiebotScripts, updatePartytownScripts]);

    return (
        <CookiebotContext.Provider
            value={{
                cookieConsent,
                runCookiebotScripts,
                updatePartytownScripts,
            }}
        >
            {children}
        </CookiebotContext.Provider>
    );
}

export const useCookiebot = (): CookieConsentState => {
    const context = React.useContext(CookiebotContext);

    if (!context) {
        throw new Error('useCookiebot must be used within a CookiebotProvider');
    }

    return context.cookieConsent;
};
