import * as React from 'react';

export interface CookieConsentState {
    necessary: boolean;
    preferences: boolean;
    statistics: boolean;
    marketing: boolean;
}

export interface CookiebotContextType {
    cookieConsent: CookieConsentState;
    runCookiebotScripts: () => void;
    updatePartytownScripts: () => void;
}

const CookiebotContext = React.createContext<CookiebotContextType>({
    cookieConsent: {
        necessary: false,
        preferences: false,
        statistics: false,
        marketing: false,
    },
    runCookiebotScripts: () => {},
    updatePartytownScripts: () => {},
});

export default CookiebotContext;
