import * as React from 'react';
import type { Maybe, Settings } from '@types/gatsby-types';
import { graphql, useStaticQuery } from 'gatsby';
import CookiebotPartytownScript from 'analytics/components/CookiebotPartytownScript';

const query = graphql`
    query HotjarPixelQuery {
        settings {
            hotjar {
                id
            }
        }
    }
`;

interface HotjarPixelQuery {
    settings: Maybe<Pick<Settings, 'convert'>>;
}

export default function HotjarPixel() {
    const { settings } = useStaticQuery<HotjarPixelQuery>(query);

    const hotjarId = settings?.hotjar?.id;

    if (hotjarId) {
        return (
            <CookiebotPartytownScript
                id="hotjar-pixel"
                cookieType="statistics"
                content={`
                (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:${hotjarId},hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.defer=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                `}
            />
        );
    }

    return null;
}
