import * as React from 'react';
import type { Maybe, Settings } from '@types/gatsby-types';
import { graphql, ScriptStrategy, useStaticQuery } from 'gatsby';
import Script from 'analytics/components/Script';

const query = graphql`
    query TiktokPixelQuery {
        settings {
            tiktok {
                enabled
                pixelId
            }
        }
    }
`;

interface TiktokPixelQuery {
    settings: Maybe<Pick<Settings, 'tiktok'>>;
}

export default function TiktokPixel() {
    const { settings } = useStaticQuery<TiktokPixelQuery>(query);
    const pixelId = settings?.tiktok?.pixelId;
    const enabled = settings?.tiktok?.enabled;

    if (enabled && pixelId) {
        /* eslint-disable max-len */
        return (
            <Script
                strategy={ScriptStrategy.idle}
                cookieType="marketing"
                content={`
                !function (w, d, t) {
		            w.TiktokAnalyticsObject=t;
		            var ttq=w[t]=w[t]||[];
		            ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){
		                t[e]=function(){
		                    t.push([e].concat(Array.prototype.slice.call(arguments,0)))
		                }
		            };
                    for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
                    ttq.instance=function(t){
                        for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);
                        return e
                    },ttq.load=function(e,n){
                        var i="https://analytics.tiktok.com/i18n/pixel/events.js";
                        ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};
                        n=document.createElement("script");
                        n.type="text/javascript",n.defer=!0,n.src=i+"?sdkid="+e+"&lib="+t;
                        e=document.getElementsByTagName("script")[0];
                        e.parentNode.insertBefore(n,e)
                    };
		
		            ttq.load('${pixelId}');
		            ttq.page();
                }(window, document, 'ttq');`}
            />
        );
    }

    return null;
}
