import * as React from 'react';
import { IS_COOKIEBOT_ENABLED } from 'core/components/CookiebotScript/CookiebotScript';
import { CookieType } from 'lib/types';

interface CookiebotPartytownScriptProps {
    src?: string;
    content?: string;
    id: string;
    cookieType: CookieType;
}

export default function CookiebotPartytownScript({
    src,
    content,
    id,
    cookieType,
}: CookiebotPartytownScriptProps) {
    React.useEffect(() => {
        const script = document.createElement('script');
        script.id = id;
        if (src) {
            script.src = src;
        }
        if (content) {
            script.innerHTML = content;
        }

        if (IS_COOKIEBOT_ENABLED) {
            // Initially block the script for Cookiebot
            script.type = 'text/plain';
            script.setAttribute('data-cookieconsent', cookieType);

            // used to detect which scripts should be updated on consent change
            script.setAttribute('data-partytown', 'true');
        } else {
            script.type = 'text/partytown';
        }

        // Add to the DOM
        document.body.appendChild(script);

        return () => {
            // Cleanup script and listener on unmount
            const existingScript = document.getElementById(id);
            if (existingScript)
                existingScript.parentNode?.removeChild(existingScript);
        };
    }, [src, content, id, cookieType]);

    return null;
}
