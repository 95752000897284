import * as React from 'react';
import {
    ScriptStrategy,
    WrapPageElementNodeArgs,
    WrapRootElementNodeArgs,
} from 'gatsby';
import Script from 'analytics/components/Script';
import App from './gatsby/App';
import PostBodyComponents from './src/ssr/components/PostBodyComponents';

export function wrapRootElement({ element }: WrapRootElementNodeArgs) {
    return <App>{element}</App>;
}

const myFontsScript = `const link = document.getElementById('my-fonts-count');
    if (link) {
        link.setAttribute('onload', 'this.rel="stylesheet";this.onload=null;');
    }`;

export const wrapPageElement = ({
    element,
    props,
}: WrapPageElementNodeArgs) => {
    const isBookingPage = props.location.pathname.includes('/booking');
    const isCMS = props.location.pathname.includes('/admin');

    return (
        <>
            {element}
            <Script
                strategy={ScriptStrategy.idle}
                timeoutMs={5000}
                content={myFontsScript}
                cookieType="necessary"
            />
            <script
                dangerouslySetInnerHTML={{
                    __html: `document.documentElement.classList.remove('no-js');
    // detect firefox
    if (navigator.userAgent.match(/firefox/i)) {
        document.documentElement.classList.add('ff');
    } else {
        document.documentElement.classList.add('no-ff');
    }`,
                }}
            />
            <PostBodyComponents
                key="post-body-components"
                isBookingPage={isBookingPage}
                isCMS={isCMS}
            />
        </>
    );
};
