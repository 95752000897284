import * as React from 'react';
import { Provider } from 'react-redux';
import createStore from 'core/redux/store';
import { messages } from 'lib/i18n';
import loadable from 'core/hoc/loadable';
import type { IntlConfig } from 'react-intl/src/types';
import ABTestingProvider from 'core/components/ABTestingProvider';
import CookiebotProvider from 'core/components/CookiebotProvider';

const IntlProvider = loadable<React.PropsWithChildren<IntlConfig>>(() =>
    import('react-intl').then((components) => ({
        default: components.IntlProvider,
    })),
);

function App({ children }: React.PropsWithChildren<unknown>) {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    const store = createStore();

    return (
        <IntlProvider messages={messages} locale="en" defaultLocale="en">
            <CookiebotProvider>
                <ABTestingProvider>
                    <Provider store={store}>{children}</Provider>
                </ABTestingProvider>
            </CookiebotProvider>
        </IntlProvider>
    );
}

export default App;
