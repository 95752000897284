import { ScriptStrategy } from 'gatsby';
import { IS_BROWSER } from 'core/constants';
import { CookieType } from 'lib/types';
import { IS_COOKIEBOT_ENABLED } from 'core/components/CookiebotScript/CookiebotScript';

function injectScript(
    cookieType: CookieType,
    timeoutMs: number = 0,
    src?: string,
    content?: string,
    attributes?: Record<string, string | undefined>,
    id?: string,
    onLoad?: () => void,
    type?: string,
): () => void {
    let script: ReturnType<typeof document.createElement>;
    if (src || content) {
        setTimeout(() => {
            let scriptExists = false;

            if (id) {
                const existingScript = document.getElementById(id);

                if (existingScript) {
                    script = existingScript;
                    scriptExists = true;
                }
            }

            script = document.createElement('script');

            if (src) {
                script.setAttribute('src', src);
                script.setAttribute('defer', 'defer');
            } else if (content) {
                script.appendChild(document.createTextNode(content));
            }

            if (attributes) {
                for (const [key, value] of Object.entries(attributes)) {
                    if (value) {
                        script.setAttribute(key, value);
                    }
                }
            }

            if (id) {
                script.id = id;
            }

            if (onLoad) {
                script.onload = onLoad;
            }

            if (type) {
                script.setAttribute('type', type);
            }

            // in order to block script execution, Cookiebot expects them to be set as text/plain.
            // once consent is given, they are enabled again.
            // https://support.cookiebot.com/hc/en-us/articles/4405978132242-Manual-cookie-blocking
            if (IS_COOKIEBOT_ENABLED && cookieType !== 'necessary') {
                script.setAttribute('type', 'text/plain');
                script.setAttribute('data-cookieconsent', cookieType);
            }

            if (!scriptExists) {
                document.body.appendChild(script);
            }
        }, timeoutMs);
    }

    return () => {
        if (script) {
            script.parentElement?.removeChild(script);
        }
    };
}

export default function renderScript(
    strategy: Exclude<ScriptStrategy, ScriptStrategy.offMainThread>,
    cookieType: CookieType,
    timeoutMs: number = 0,
    src?: string,
    content?: string,
    attributes?: Record<string, string | undefined>,
    id?: string,
    onLoad?: () => void,
    type?: string,
): () => void {
    if (!IS_BROWSER || (!src && !content)) {
        return () => {};
    }

    const unregisterRef: { current?: () => void } = {};

    switch (strategy) {
        case ScriptStrategy.postHydrate:
            unregisterRef.current = injectScript(
                cookieType,
                timeoutMs,
                src,
                content,
                attributes,
                id,
                onLoad,
                type,
            );
            break;
        case ScriptStrategy.idle:
            if ('requestIdleCallback' in window) {
                requestIdleCallback(() => {
                    unregisterRef.current = injectScript(
                        cookieType,
                        timeoutMs,
                        src,
                        content,
                        attributes,
                        id,
                        onLoad,
                        type,
                    );
                });
            } else {
                setTimeout(() => {
                    unregisterRef.current = injectScript(
                        cookieType,
                        timeoutMs,
                        src,
                        content,
                        attributes,
                        id,
                        onLoad,
                        type,
                    );
                }, 500);
            }
            break;
        default:
            // noop
            break;
    }

    return () => {
        if (unregisterRef.current) {
            unregisterRef.current();
        }
    };
}
