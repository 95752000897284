import { Species } from '@bondvet/types/booking';
import { KnownPatient } from 'booking/hooks/usePatientsAndContactInfoQuery';
import {
    AppointmentData,
    BookingStep,
    BOOKING_STEPS,
    Provider,
} from '../lib/types';

export const SET_BOOTED = 'booking/SET_BOOTED';
export const SET_SESSION_ID = 'booking/SET_SESSION_ID';
export const SET_PET = 'booking/SET_PET';
export const SET_SPECIES = 'booking/SET_SPECIES';
export const SET_BREED = 'booking/SET_BREED';
export const SET_KNOWS_EXACT_BIRTHDATE = 'booking/SET_KNOWS_EXACT_BIRTHDATE';
export const SET_BIRTHDATE = 'booking/SET_BIRTHDATE';
export const SET_AGE = 'booking/SET_AGE';
export const SET_AGE_UNIT = 'booking/SET_AGE_UNIT';
export const SET_SEX = 'booking/SET_SEX';
export const SET_REPRODUCTIVE_HEALTH = 'booking/SET_REPRODUCTIVE_HEALTH';
export const SELECT_CITY = 'booking/SELECT_CITY';
export const SELECT_LOCATION = 'booking/SELECT_LOCATION';
export const SELECT_PROVIDER = 'booking/SELECT_PROVIDER';
export const SELECT_DATE = 'booking/SELECT_DATE';
export const SELECT_TIME = 'booking/SELECT_TIME';
export const SET_NEARBY_AVAILABILITY = 'booking/SET_NEARBY_AVAILABILITY';
export const SET_REASON = 'booking/SET_REASON';
export const SET_NOTES = 'booking/SET_NOTES';
export const SET_FIRST_NAME = 'booking/SET_FIRST_NAME';
export const SET_LAST_NAME = 'booking/SET_LAST_NAME';
export const SET_EMAIL = 'booking/SET_EMAIL';
export const SET_PHONE_NUMBER = 'booking/SET_PHONE_NUMBER';
export const SET_SMS_PREFERENCES = 'booking/SET_SMS_PREFERENCES';
export const SET_SMS_PREFERENCES_DIRTY = 'booking/SET_SMS_PREFERENCES_DIRTY';
export const SET_PROMO_CODE = 'booking/SET_PROMO_CODE';
export const SET_SETUP_INTENT_ID = 'booking/SET_SETUP_INTENT_ID';
export const SET_HAS_EXISTING_CC_DETAILS =
    'booking/SET_HAS_EXISTING_CC_DETAILS';
export const SET_LOADING_EXISTING_CC_DETAILS =
    'booking/SET_LOADING_EXISTING_CC_DETAILS';
export const SET_CLIENT_REQUIRES_CREDIT_CARD =
    'booking/SET_CLIENT_REQUIRES_CREDIT_CARD';
export const SET_LOADING_CLIENT_REQUIRES_CREDIT_CARD =
    'booking/SET_LOADING_CLIENT_REQUIRES_CREDIT_CARD';
export const SET_IS_NEW_CLIENT = 'booking/SET_IS_NEW_CLIENT';
export const SET_KNOWN_PATIENTS = 'booking/SET_KNOWN_PATIENTS';
export const SET_CLIENT_ID = 'booking/SET_CLIENT_ID';
export const SET_ANIMAL_ID = 'booking/SET_ANIMAL_ID';
export const SET_LAST4 = 'booking/SET_LAST4';

export const SET_STEPS = 'booking/SET_STEPS';
export const SET_STEP_VALIDATED = 'booking/SET_STEP_VALIDATED';

export const SUBMIT = 'booking/SUBMIT';
export const FINISHED = 'booking/FINISHED';
export const FAILED = 'booking/FAILED';

export { BookingStep, BOOKING_STEPS };

export type SetBootedAction = {
    type: typeof SET_BOOTED;
    payload: {
        booted: boolean;
    };
};

export type SetSessionIdAction = {
    type: typeof SET_SESSION_ID;
    payload: {
        sessionId: string;
    };
};

export type SetPetAction = {
    type: typeof SET_PET;
    payload: {
        pet: string;
    };
};

export type SetSpeciesAction = {
    type: typeof SET_SPECIES;
    payload: {
        species: Species;
    };
};

export type SetBreedAction = {
    type: typeof SET_BREED;
    payload: {
        breed: string;
    };
};

export type SetBirthdateAction = {
    type: typeof SET_BIRTHDATE;
    payload: {
        birthdate: Date | null;
    };
};

export type SetAgeAction = {
    type: typeof SET_AGE;
    payload: {
        age: number;
    };
};

export type SetAgeUnitAction = {
    type: typeof SET_AGE_UNIT;
    payload: {
        ageUnit: 'weeks' | 'months' | 'years';
    };
};

export type SetSexAction = {
    type: typeof SET_SEX;
    payload: {
        sex: 'female' | 'male' | 'unknown';
    };
};

export type SetReproductiveHealthAction = {
    type: typeof SET_REPRODUCTIVE_HEALTH;
    payload: {
        reproductiveHealth: PetReproductiveHealthType;
    };
};

export type SelectCityAction = {
    type: typeof SELECT_CITY;
    payload: {
        cityId: string;
    };
};

export type SelectLocationAction = {
    type: typeof SELECT_LOCATION;
    payload: {
        locationId: string;
    };
};

export type SelectProviderAction = {
    type: typeof SELECT_PROVIDER;
    payload: {
        provider: Provider | null;
    };
};

export type SelectDateAction = {
    type: typeof SELECT_DATE;
    payload: {
        date: string;
    };
};

export type SelectTimeAction = {
    type: typeof SELECT_TIME;
    payload: {
        time: string;
    };
};

export type SetNearbyAvailabilityAction = {
    type: typeof SET_NEARBY_AVAILABILITY;
    payload: {
        isNearbyAvailableSelected: boolean;
    };
};

export type SetReasonAction = {
    type: typeof SET_REASON;
    payload: {
        reason: string;
    };
};

export type SetNotesAction = {
    type: typeof SET_NOTES;
    payload: {
        notes: string;
    };
};

export type SetFirstNameAction = {
    type: typeof SET_FIRST_NAME;
    payload: {
        firstName: string;
    };
};

export type SetLastNameAction = {
    type: typeof SET_LAST_NAME;
    payload: {
        lastName: string;
    };
};

export type SetEmailAction = {
    type: typeof SET_EMAIL;
    payload: {
        email: string;
    };
};

export type SetPhoneNumberAction = {
    type: typeof SET_PHONE_NUMBER;
    payload: {
        phoneNumber: string;
    };
};

export type SetSmsPreferencesAction = {
    type: typeof SET_SMS_PREFERENCES;
    payload: {
        smsPreferences: boolean;
    };
};

export type SetSmsPreferencesDirtyAction = {
    type: typeof SET_SMS_PREFERENCES_DIRTY;
    payload: {
        smsPreferencesDirty: boolean;
    };
};

export type SetPromoCodeAction = {
    type: typeof SET_PROMO_CODE;
    payload: {
        promoCode: string;
    };
};

export type SetSetupIntentIdAction = {
    type: typeof SET_SETUP_INTENT_ID;
    payload: {
        setupIntentId: string;
    };
};

export type SetHasExistingCCDetailsAction = {
    type: typeof SET_HAS_EXISTING_CC_DETAILS;
    payload: {
        hasExistingCCDetails: boolean;
    };
};

export type SetIsNewClientAction = {
    type: typeof SET_IS_NEW_CLIENT;
    payload: {
        isNewClient: boolean | undefined;
    };
};

export type SetKnownPatientsAction = {
    type: typeof SET_KNOWN_PATIENTS;
    payload: {
        knownPatients: KnownPatient[];
    };
};

export type SetKnowsExactBirthdateAction = {
    type: typeof SET_KNOWS_EXACT_BIRTHDATE;
    payload: {
        knowsExactBirthdate: boolean;
    };
};

export type SetClientIdAction = {
    type: typeof SET_CLIENT_ID;
    payload: {
        clientId: string;
    };
};

export type SetAnimalIdAction = {
    type: typeof SET_ANIMAL_ID;
    payload: {
        animalId: string;
    };
};

export type SetLast4Action = {
    type: typeof SET_LAST4;
    payload: {
        last4: string;
    };
};

export type SetLoadingExistingCCDetailsAction = {
    type: typeof SET_LOADING_EXISTING_CC_DETAILS;
    payload: {
        isLoading: boolean;
    };
};

export type SetClientRequiresCreditCardAction = {
    type: typeof SET_CLIENT_REQUIRES_CREDIT_CARD;
    payload: {
        clientRequiresCreditCard: boolean;
    };
};

export type SetLoadingClientRequiresCreditCardAction = {
    type: typeof SET_LOADING_CLIENT_REQUIRES_CREDIT_CARD;
    payload: {
        isLoading: boolean;
    };
};

export type SetStepsAction = {
    type: typeof SET_STEPS;
    payload: {
        steps: ReadonlyArray<BookingStep>;
    };
};

export type SetStepValidatedAction = {
    type: typeof SET_STEP_VALIDATED;
    payload: {
        id: BookingStep;
        isValidated: boolean;
    };
};

export type SubmitAction = {
    type: typeof SUBMIT;
};

export type FinishedAction = {
    type: typeof FINISHED;
    payload: {
        appointment: AppointmentData;
    };
};

export type FailedAction = {
    type: typeof FAILED;
    payload: {
        error: string;
    };
};

export type BookingFormActionTypes =
    | SetSessionIdAction
    | SetPetAction
    | SetSpeciesAction
    | SetBreedAction
    | SetKnowsExactBirthdateAction
    | SetBirthdateAction
    | SetAgeAction
    | SetAgeUnitAction
    | SetLast4Action
    | SetSexAction
    | SetReproductiveHealthAction
    | SelectCityAction
    | SelectLocationAction
    | SelectProviderAction
    | SelectDateAction
    | SelectTimeAction
    | SetNearbyAvailabilityAction
    | SetReasonAction
    | SetNotesAction
    | SetFirstNameAction
    | SetLastNameAction
    | SetEmailAction
    | SetPhoneNumberAction
    | SetSmsPreferencesAction
    | SetSmsPreferencesDirtyAction
    | SetPromoCodeAction
    | SetSetupIntentIdAction
    | SetHasExistingCCDetailsAction
    | SetIsNewClientAction
    | SetKnownPatientsAction
    | SetClientIdAction
    | SetAnimalIdAction
    | SetLoadingExistingCCDetailsAction
    | SetStepsAction
    | SetStepValidatedAction
    | SubmitAction
    | FinishedAction
    | FailedAction
    | SetClientRequiresCreditCardAction
    | SetLoadingClientRequiresCreditCardAction;

export type BookingActionTypes = SetBootedAction | BookingFormActionTypes;

export type BookingState = {
    booted: boolean;
    steps: ReadonlyArray<BookingStep>;
    validatedSteps: Set<BookingStep>;
    submitting: boolean;
    sessionId?: string;

    pet?: string;
    species?: Species;
    breed: string;
    knowsExactBirthdate: boolean;
    birthdate: Date | null;
    age?: number;
    ageUnit: 'weeks' | 'months' | 'years';
    sex?: 'female' | 'male' | 'unknown';
    reproductiveHealth?: 'Yes' | 'No' | 'Unknown';
    cityId: string;
    locationId: string;
    date: string;
    time: string;
    provider: Provider | null;
    isNearbyAvailableSelected: boolean;
    reason: string;
    notes: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    smsPreferences: boolean;
    smsPreferencesDirty: boolean;
    promoCode: string;

    hasExistingCCDetails: boolean;
    isLoadingExistingCCDetails: boolean;
    setupIntentId: string | null;

    isNewClient: boolean | undefined;
    knownPatients: KnownPatient[];
    clientId: string;
    animalId: string;

    appointment: AppointmentData | null;
    error: string | null;

    last4: string;
    clientRequiresCreditCard: boolean;
    isLoadingClientRequiresCreditCard: boolean;
};

export type PetReproductiveHealthType = 'Yes' | 'No' | 'Unknown';
